import { createSlice } from '@reduxjs/toolkit';
import { BooleanKey } from '../../types/legal-fees';
import { WebsiteUser } from '../../types/websiteUser';
import { RootState } from '../store';

export interface UserState {
  loggedIn: boolean | null;
  profile?: WebsiteUser;
  customAvatar: boolean;
  // Details are cached here from enquiry form
  details: {
    name: string;
    email: string;
    phoneNumber: string;
    buyingStatus: string;
    ukResident: BooleanKey | '';
    firstName: string;
    lastName: string;
  };
}

interface SetAvatarUrl {
  payload: string;
}

interface SetBuyingStatus {
  payload: string;
}

interface SetUkResident {
  payload: UserState['details']['ukResident'];
}

interface SetPersonalDetails {
  payload: {
    name: string;
    email: string;
    phoneNumber: string;
  };
}

interface SetLoggedIn {
  payload: boolean;
}

interface SetProfile {
  payload: WebsiteUser;
}

const initialState: UserState = {
  loggedIn: null,
  customAvatar: false,
  details: {
    buyingStatus: '',
    name: '',
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    ukResident: 'TRUE',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAvatarUrl: (state, action: SetAvatarUrl) => {
      if (state.profile) {
        state.profile.avatarUrl = action.payload;
      }

      state.customAvatar = action.payload.includes('rackcdn.com');
    },
    setBuyingStatus: (state, action: SetBuyingStatus) => {
      state.details.buyingStatus = action.payload;
    },
    setUkResident: (state, action: SetUkResident) => {
      state.details.ukResident = action.payload;
    },
    setPersonalDetails: (state, action: SetPersonalDetails) => {
      state.details.name = action.payload.name;
      state.details.email = action.payload.email;
      state.details.phoneNumber = action.payload.phoneNumber;
    },
    setProfile: (state, action: SetProfile) => {
      state.profile = { ...action.payload };

      state.details.name = `${action.payload.firstName} ${action.payload.lastName}`;
      state.details.email = action.payload.email;
      state.details.phoneNumber = action.payload.telephone;

      if (action.payload.avatarUrl) {
        state.customAvatar = action.payload.avatarUrl.includes('rackcdn.com');
      }
    },
    setLoggedIn: (state, action: SetLoggedIn) => {
      if (state.loggedIn && !action.payload) {
        state = {
          ...initialState,
        };
      }

      state.loggedIn = action.payload;

      return state;
    },
    clearUser: (state) => {
      state = { ...initialState };
      return state;
    },
  },
});

export const { clearUser, setAvatarUrl, setBuyingStatus, setUkResident, setPersonalDetails, setLoggedIn, setProfile } =
  userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
